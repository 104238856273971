import { register } from "./register";
import { CODES, KEYS } from "../keys";
import { ToolButton } from "../components/ToolButton";
import { enterFullscreen, exitFullscreen, save } from "../components/icons";
import { t } from "../i18n";
import IServ from "../iserv";

export const actionSaveScene = register({
  name: "saveScene",
  trackEvent: false,
  perform: async (elements, appState, _, app) => {
    IServ.requestSave();
    return {
      commitToHistory: false,
    };
  },
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.S,
  PanelComponent: ({ updateData, appState, data }) => {
    return (
      <ToolButton
        type="button"
        icon={save}
        title={t("buttons.saveToServer")}
        aria-label={t("buttons.saveToServer")}
        onClick={updateData}
        size={data?.size || "medium"}
      />
    );
  },
});

export const actionIServFullscreen = register({
  name: "iservFullscreen",
  trackEvent: false,
  perform: async (elements, appState, _, app) => {
    IServ.toggleFullscreen();
    return {
      appState: {
        ...appState,
        isFullscreen: !appState.isFullscreen,
      },
      commitToHistory: false,
    };
  },
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.code === CODES.F,
  PanelComponent: ({ updateData, appState, data }) => (
    <ToolButton
      type="button"
      icon={appState.isFullscreen ? exitFullscreen : enterFullscreen}
      title={t("buttons.fullScreen")}
      aria-label={t("buttons.fullScreen")}
      onClick={updateData}
      size={data?.size || "medium"}
    />
  ),
});
