import { getIServDomain } from "../excalidraw-app/data/config";

type IServConfig = {
  displayName: string;
  jwt: string;
  excalidrawUrl: string;
  canWrite: boolean;
  languageCode: string;
  theme: string;
};

enum ISERV_EVENTS {
  // sent to IServ
  SceneUpdate = "IServ.Excalidraw.SceneUpdated",
  UserDataRequest = "IServ.Excalidraw.UserDataRequest",
  SceneSaved = "IServ.Excalidraw.SceneSaved",
  SavingFiles = "IServ.Excalidraw.SavingFiles",
  FilesSaved = "IServ.Excalidraw.FilesSaved",
  ToggleFullscreen = "IServ.Excalidraw.ToggleFullscreen",
  // received from IServ
  UserData = "IServ.Excalidraw.UserData",
  SaveRequested = "IServ.Excalidraw.SaveRequested",
}

let config: IServConfig | null = null;
let iservDomain: string | null = null;

export type SaveRequestCallback = (() => void) | null;
let saveRequestCallback: SaveRequestCallback = null;

async function init(): Promise<IServConfig> {
  return new Promise<IServConfig>(async (resolve, reject) => {
    iservDomain = await getIServDomain();
    window.addEventListener("message", (event) => {
      if (event.origin !== iservDomain) {
        reject();
        return;
      }
      if (event.data.type === ISERV_EVENTS.UserData) {
        if (null == config) {
          const { displayName, jwt, excalidrawUrl, canWrite, theme } =
            event.data.userData;
          const languageCode = event.data.userData.languageCode.replace(
            "_",
            "-",
          ); // normalize to excalidraw i18n
          config = {
            displayName,
            jwt,
            excalidrawUrl,
            canWrite,
            languageCode,
            theme,
          };
        }
        resolve(config);
      }

      switch (event.data.type) {
        case ISERV_EVENTS.SaveRequested:
          if (saveRequestCallback) {
            saveRequestCallback();
          }
          break;
      }
    });

    window.parent.postMessage(
      { type: ISERV_EVENTS.UserDataRequest },
      iservDomain,
    );
  });
}

function getConfig(): IServConfig {
  if (null == config) {
    throw new Error("Could not retrieve config, it was not synchronized yet!");
  }

  return config;
}

function notifySceneChange(): void {
  window.parent.postMessage({ type: ISERV_EVENTS.SceneUpdate }, iservDomain!);
}

function notifySceneSaved(): void {
  window.parent.postMessage({ type: ISERV_EVENTS.SceneSaved }, iservDomain!);
}

function notifySavingFiles(): void {
  window.parent.postMessage({ type: ISERV_EVENTS.SavingFiles }, iservDomain!);
}

function notifyFilesSaved(): void {
  window.parent.postMessage({ type: ISERV_EVENTS.FilesSaved }, iservDomain!);
}

function toggleFullscreen(): void {
  window.parent.postMessage(
    { type: ISERV_EVENTS.ToggleFullscreen },
    iservDomain!,
  );
}

function requestSave(): void {
  if (saveRequestCallback) {
    saveRequestCallback();
  }
}

function onSaveRequest(callback: SaveRequestCallback): void {
  saveRequestCallback = callback;
}

export default {
  init,
  getConfig,
  onSaveRequest,
  notifySceneChange,
  notifySceneSaved,
  notifySavingFiles,
  notifyFilesSaved,
  toggleFullscreen,
  requestSave,
};
