import ReactDOM from "react-dom";
import ExcalidrawApp from "./excalidraw-app";

import "./excalidraw-app/pwa";
import "./excalidraw-app/sentry";
import IServ from "./iserv";
window.__EXCALIDRAW_SHA__ = process.env.REACT_APP_GIT_SHA;

IServ.init().then(() =>
  ReactDOM.render(<ExcalidrawApp />, document.getElementById("root")),
);
